define("ember-resources/-private/use-function", ["exports", "@glimmer/tracking/primitives/cache", "@ember/debug", "@ember/helper", "ember-resources/-private/resources/function-runner", "ember-resources/-private/utils"], function (_exports, _cache, _debug, _helper, _functionRunner, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useFunction = useFunction;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function useFunction() {
    for (var _len = arguments.length, passedArgs = new Array(_len), _key = 0; _key < _len; _key++) {
      passedArgs[_key] = arguments[_key];
    }

    let [context] = passedArgs;
    let initialValue;
    let fn;
    let thunk;
    (false && !(passedArgs[1] !== undefined) && (0, _debug.assert)("Expected second argument to useFunction to either be an initialValue or the function to run", passedArgs[1] !== undefined));

    if (isVanillaArgs(passedArgs)) {
      fn = passedArgs[1];
      thunk = passedArgs[2];
    } else {
      initialValue = passedArgs[1];
      fn = passedArgs[2];
      thunk = passedArgs[3];
    }

    let target = buildUnproxiedFunctionResource(context, initialValue, fn, thunk || _utils.DEFAULT_THUNK); // :(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    return (0, _utils.proxyClass)(target);
  }

  function isVanillaArgs(args) {
    return typeof args[1] === 'function';
  }

  const FUNCTION_CACHE = new WeakMap();
  /**
   * The function is wrapped in a bespoke resource per-function definition
   * because passing a vanilla function to invokeHelper would trigger a
   * different HelperManager, which we want to work a bit differently.
   * See:
   *  - function HelperManager in ember-could-get-used-to-this
   *  - Default Managers RFC
   *
   */

  function buildUnproxiedFunctionResource(context, initial, fn, thunk) {
    let resource;
    let klass;
    let existing = FUNCTION_CACHE.get(fn);

    if (existing) {
      klass = existing;
    } else {
      klass = class AnonymousFunctionRunner extends _functionRunner.FunctionRunner {
        constructor() {
          super(...arguments);

          _defineProperty(this, _functionRunner.INITIAL_VALUE, initial);

          _defineProperty(this, _functionRunner.FUNCTION_TO_RUN, fn);
        }

      };
      FUNCTION_CACHE.set(fn, klass);
    }

    return {
      get value() {
        if (!resource) {
          resource = (0, _helper.invokeHelper)(context, klass, () => {
            return (0, _utils.normalizeThunk)(thunk);
          });
        }

        return (0, _cache.getValue)(resource);
      }

    };
  }
});