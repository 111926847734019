define("ember-bootstrap/components/bs-button-group/button", ["exports", "@ember/component", "@ember/template-factory", "@ember/array", "ember-bootstrap/components/bs-button"], function (_exports, _component, _templateFactory, _array, _bsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    disabled={{this.__disabled}}
    type={{this.buttonType}}
    class="btn {{if this.active "active"}} {{if this.block "btn-block"}} {{bs-size-class "btn" @size}} {{bs-type-class "btn" @type default=(if (macroCondition (macroGetOwnConfig "isBS3")) "default" "secondary") outline=@outline}}"
    ...attributes
    {{on "click" this.handleClick}}
    {{did-update this.resetState @reset}}
  >
    {{#if this.icon}}<i class={{this.icon}}></i> {{/if}}{{this.text}}{{yield
      (hash
        isFulfilled=this.isFulfilled
        isPending=this.isPending
        isRejected=this.isRejected
        isSettled=this.isSettled
      )
    }}
  </button>
  */
  {
    "id": "SY1twyo4",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,0,[\"__disabled\"]]],[16,4,[30,0,[\"buttonType\"]]],[16,0,[29,[\"btn \",[52,[30,0,[\"active\"]],\"active\"],\" \",[52,[30,0,[\"block\"]],\"btn-block\"],\" \",[28,[37,1],[\"btn\",[30,1]],null],\" \",[28,[37,2],[\"btn\",[30,2]],[[\"default\",\"outline\"],[\"secondary\",[30,3]]]]]]],[17,4],[4,[38,3],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,4],[[30,0,[\"resetState\"]],[30,5]],null],[12],[1,\"\\n  \"],[41,[30,0,[\"icon\"]],[[[10,\"i\"],[15,0,[30,0,[\"icon\"]]],[12],[13],[1,\" \"]],[]],null],[1,[30,0,[\"text\"]]],[18,6,[[28,[37,6],null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[30,0,[\"isFulfilled\"]],[30,0,[\"isPending\"]],[30,0,[\"isRejected\"]],[30,0,[\"isSettled\"]]]]]]],[1,\"\\n\"],[13]],[\"@size\",\"@type\",\"@outline\",\"&attrs\",\"@reset\",\"&default\"],false,[\"if\",\"bs-size-class\",\"bs-type-class\",\"on\",\"did-update\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/components/bs-button-group/button.hbs",
    "isStrictMode": false
  });

  /**
   Internal component for button-group buttons
  
   @class ButtonGroupButton
   @namespace Components
   @extends Components.Button
   @private
   */
  class ButtonGroupButton extends _bsButton.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, '__ember-bootstrap_subclass', true);
    }

    /**
     * @property groupValue
     * @private
     */

    /**
     * @property buttonGroupType
     * @type string
     * @private
     */

    /**
     * @property active
     * @type boolean
     * @readonly
     * @private
     */
    get active() {
      let {
        value,
        groupValue
      } = this.args;

      if (this.args.buttonGroupType === 'radio') {
        return value === groupValue;
      } else {
        if ((0, _array.isArray)(groupValue)) {
          return groupValue.indexOf(value) !== -1;
        }
      }

      return false;
    }

  }

  _exports.default = ButtonGroupButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ButtonGroupButton);
});