define("ember-resources/-private/resources/lifecycle", ["exports", "@glimmer/tracking/primitives/cache", "@ember/application", "@ember/destroyable", "@ember/helper"], function (_exports, _cache, _application, _destroyable, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LifecycleResource = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class LifecycleResource {
    constructor(owner, args) {
      this.args = args;
      (0, _application.setOwner)(this, owner);
    }

  }

  _exports.LifecycleResource = LifecycleResource;

  class LifecycleResourceManager {
    constructor(owner) {
      _defineProperty(this, "capabilities", (0, _helper.capabilities)('3.23', {
        hasValue: true,
        hasDestroyable: true
      }));

      this.owner = owner;
    }

    createHelper(Class, args) {
      let owner = this.owner;
      let instance;
      let cache = (0, _cache.createCache)(() => {
        if (instance === undefined) {
          instance = setupInstance(cache, Class, owner, args);
        } else {
          instance.update();
        }

        return instance;
      });
      return cache;
    }

    getValue(cache) {
      let instance = (0, _cache.getValue)(cache);
      return instance;
    }

    getDestroyable(cache) {
      return cache;
    }

  }

  function setupInstance(cache, Class, owner, args) {
    let instance = new Class(owner, args);
    (0, _destroyable.associateDestroyableChild)(cache, instance);

    if ('setup' in instance) {
      instance.setup();
    }

    if ('teardown' in instance) {
      (0, _destroyable.registerDestructor)(instance, () => instance.teardown());
    }

    return instance;
  }

  (0, _helper.setHelperManager)(owner => new LifecycleResourceManager(owner), LifecycleResource);
});