define("ember-resources/-private/resources/ember-concurrency-task", ["exports", "@ember/object", "ember-resources/-private/resources/lifecycle"], function (_exports, _object, _lifecycle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TaskResource = _exports.TASK = void 0;

  /* eslint-disable ember/no-get */
  const TASK = Symbol('TASK');
  _exports.TASK = TASK;

  class TaskResource extends _lifecycle.LifecycleResource {
    // Set via useTask
    // Set during setup/update
    get taskArgs() {
      return this.args.positional;
    }

    get value() {
      var _this$currentTask$val, _this$lastTask;

      // in ember-concurrency@v1, value is not consumable tracked data
      // until the task is resolved, so we need to consume the isRunning
      // property so that value updates
      (0, _object.get)(this.currentTask, 'isRunning');
      return (_this$currentTask$val = this.currentTask.value) !== null && _this$currentTask$val !== void 0 ? _this$currentTask$val : (_this$lastTask = this.lastTask) === null || _this$lastTask === void 0 ? void 0 : _this$lastTask.value;
    }

    setup() {
      this.update();
    }

    update() {
      if (this.currentTask) {
        this.lastTask = this.currentTask;
      }

      this.currentTask = this[TASK].perform(...this.taskArgs);
    }

    teardown() {
      this[TASK].cancelAll();
    }

  }

  _exports.TaskResource = TaskResource;
});