define("ember-resources/-private/use-helper", ["exports", "@glimmer/tracking/primitives/cache", "@ember/helper", "ember-resources/-private/utils"], function (_exports, _cache, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useHelper = useHelper;

  /* eslint-disable @typescript-eslint/ban-types */
  // typed-ember has not publihsed types for this yet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // typed-ember has not publihsed types for this yet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function useHelper(context, helper) {
    let thunk = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _utils.DEFAULT_THUNK;
    let resource;
    return {
      get value() {
        if (!resource) {
          resource = (0, _helper.invokeHelper)(context, helper, () => {
            return (0, _utils.normalizeThunk)(thunk);
          });
        }

        return (0, _cache.getValue)(resource); // eslint-disable-line
      }

    };
  }
});