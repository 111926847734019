define("ember-power-select/components/power-select/options", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul role="listbox" {{did-insert this.addHandlers}} {{will-destroy this.removeHandlers}} ...attributes>
    {{! template-lint-disable no-unnecessary-concat }}
    {{#if @select.loading}}
      {{#if @loadingMessage}}
        <li class="ember-power-select-option ember-power-select-option--loading-message" role="option">{{@loadingMessage}}</li>
      {{/if}}
    {{/if}}
    {{#let (component @groupComponent) (component @optionsComponent) as |Group Options|}}
      {{#each @options as |opt index|}}
        {{#if (ember-power-select-is-group opt)}}
          <Group @group={{opt}} @select={{@select}} @extra={{@extra}}>
            <Options
              @options={{opt.options}}
              @select={{@select}}
              @groupIndex="{{@groupIndex}}{{index}}."
              @optionsComponent={{@optionsComponent}}
              @groupComponent={{@groupComponent}}
              @extra={{@extra}}
              role="group"
              class="ember-power-select-options" as |option|>
              {{yield option @select}}
            </Options>
          </Group>
        {{else}}
          <li class="ember-power-select-option"
            id="{{@select.uniqueId}}-{{@groupIndex}}{{index}}"
            aria-selected="{{ember-power-select-is-selected opt @select.selected}}"
            aria-disabled={{if opt.disabled "true"}}
            aria-current="{{eq opt @select.highlighted}}"
            data-option-index="{{@groupIndex}}{{index}}"
            role="option">
            {{yield opt @select}}
          </li>
        {{/if}}
      {{/each}}
    {{/let}}
  </ul>
  
  */
  {
    "id": "iU9PJ07F",
    "block": "[[[11,\"ul\"],[24,\"role\",\"listbox\"],[17,1],[4,[38,0],[[30,0,[\"addHandlers\"]]],null],[4,[38,1],[[30,0,[\"removeHandlers\"]]],null],[12],[1,\"\\n\"],[41,[30,2,[\"loading\"]],[[[41,[30,3],[[[1,\"      \"],[10,\"li\"],[14,0,\"ember-power-select-option ember-power-select-option--loading-message\"],[14,\"role\",\"option\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null]],[]],null],[44,[[50,[30,4],0,null,null],[50,[30,5],0,null,null]],[[[42,[28,[37,6],[[28,[37,6],[[30,8]],null]],null],null,[[[41,[28,[37,7],[[30,9]],null],[[[1,\"        \"],[8,[30,6],null,[[\"@group\",\"@select\",\"@extra\"],[[30,9],[30,2],[30,11]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,7],[[24,\"role\",\"group\"],[24,0,\"ember-power-select-options\"]],[[\"@options\",\"@select\",\"@groupIndex\",\"@optionsComponent\",\"@groupComponent\",\"@extra\"],[[30,9,[\"options\"]],[30,2],[29,[[30,12],[30,10],\".\"]],[30,5],[30,4],[30,11]]],[[\"default\"],[[[[1,\"\\n            \"],[18,14,[[30,13],[30,2]]],[1,\"\\n          \"]],[13]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"li\"],[14,0,\"ember-power-select-option\"],[15,1,[29,[[30,2,[\"uniqueId\"]],\"-\",[30,12],[30,10]]]],[15,\"aria-selected\",[29,[[28,[37,9],[[30,9],[30,2,[\"selected\"]]],null]]]],[15,\"aria-disabled\",[52,[30,9,[\"disabled\"]],\"true\"]],[15,\"aria-current\",[29,[[28,[37,10],[[30,9],[30,2,[\"highlighted\"]]],null]]]],[15,\"data-option-index\",[29,[[30,12],[30,10]]]],[14,\"role\",\"option\"],[12],[1,\"\\n          \"],[18,14,[[30,9],[30,2]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[9,10]],null]],[6,7]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@select\",\"@loadingMessage\",\"@groupComponent\",\"@optionsComponent\",\"Group\",\"Options\",\"@options\",\"opt\",\"index\",\"@extra\",\"@groupIndex\",\"option\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"if\",\"let\",\"component\",\"each\",\"-track-array\",\"ember-power-select-is-group\",\"yield\",\"ember-power-select-is-selected\",\"eq\"]]",
    "moduleName": "ember-power-select/components/power-select/options.hbs",
    "isStrictMode": false
  });

  const isTouchDevice = !!window && 'ontouchstart' in window;

  if (typeof FastBoot === 'undefined') {
    (function (ElementProto) {
      if (typeof ElementProto.matches !== 'function') {
        ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector;
      }

      if (typeof ElementProto.closest !== 'function') {
        ElementProto.closest = function closest(selector) {
          let element = this;

          while (element !== null && element.nodeType === 1) {
            if (element.matches(selector)) {
              return element;
            }

            element = element.parentNode;
          }

          return null;
        };
      }
    })(window.Element.prototype);
  }

  let Options = (_class = class Options extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "isTouchDevice", isTouchDevice);

      _defineProperty(this, "hasMoved", false);

      _defineProperty(this, "mouseOverHandler", _ => {});

      _defineProperty(this, "mouseUpHandler", _ => {});

      _defineProperty(this, "touchEndHandler", _ => {});

      _defineProperty(this, "touchMoveHandler", _ => {});

      _defineProperty(this, "touchStartHandler", _ => {});
    }

    addHandlers(element) {
      let role = element.getAttribute('role');

      if (role === 'group') {
        return;
      }

      let findOptionAndPerform = (action, e) => {
        if (e.target === null) return;
        let optionItem = e.target.closest('[data-option-index]');

        if (!optionItem) {
          return;
        }

        if (optionItem.closest('[aria-disabled=true]')) {
          return; // Abort if the item or an ancestor is disabled
        }

        let optionIndex = optionItem.getAttribute('data-option-index');
        if (optionIndex === null) return;
        action(this._optionFromIndex(optionIndex), e);
      };

      this.mouseUpHandler = e => findOptionAndPerform(this.args.select.actions.choose, e);

      element.addEventListener('mouseup', this.mouseUpHandler);

      if (this.args.highlightOnHover) {
        this.mouseOverHandler = e => findOptionAndPerform(this.args.select.actions.highlight, e);

        element.addEventListener('mouseover', this.mouseOverHandler);
      }

      if (this.isTouchDevice) {
        this.touchMoveHandler = _ => {
          this.hasMoved = true;

          if (element) {
            element.removeEventListener('touchmove', this.touchMoveHandler);
          }
        }; // Add touch event handlers to detect taps


        this.touchStartHandler = _ => {
          element.addEventListener('touchmove', this.touchMoveHandler);
        };

        this.touchEndHandler = e => {
          if (e.target === null) return;
          let optionItem = e.target.closest('[data-option-index]');
          if (optionItem === null) return;
          e.preventDefault();

          if (this.hasMoved) {
            this.hasMoved = false;
            return;
          }

          if (optionItem.closest('[aria-disabled=true]')) {
            return; // Abort if the item or an ancestor is disabled
          }

          let optionIndex = optionItem.getAttribute('data-option-index');
          if (optionIndex === null) return;
          this.args.select.actions.choose(this._optionFromIndex(optionIndex), e);
        };

        element.addEventListener('touchstart', this.touchStartHandler);
        element.addEventListener('touchend', this.touchEndHandler);
      }

      if (role !== 'group') {
        this.args.select.actions.scrollTo(this.args.select.highlighted);
      }
    }

    removeHandlers(element) {
      element.removeEventListener('mouseup', this.mouseUpHandler);
      element.removeEventListener('mouseover', this.mouseOverHandler);
      element.removeEventListener('touchstart', this.touchStartHandler);
      element.removeEventListener('touchmove', this.touchMoveHandler);
      element.removeEventListener('touchend', this.touchEndHandler);
    }

    _optionFromIndex(index) {
      let parts = index.split('.');
      let option = this.args.options[parseInt(parts[0], 10)];

      for (let i = 1; i < parts.length; i++) {
        option = option.options[parseInt(parts[i], 10)];
      }

      return option;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "addHandlers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addHandlers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeHandlers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeHandlers"), _class.prototype)), _class);
  _exports.default = Options;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Options);
});