define("ember-resources/-private/use-resource", ["exports", "@glimmer/tracking/primitives/cache", "@ember/debug", "@ember/helper", "ember-resources/-private/resources/lifecycle", "ember-resources/-private/resources/simple", "ember-resources/-private/utils"], function (_exports, _cache, _debug, _helper, _lifecycle, _simple, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useResource = useResource;

  /* eslint-disable @typescript-eslint/no-explicit-any */

  /* eslint-disable @typescript-eslint/ban-types */
  // typed-ember has not publihsed types for this yet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // typed-ember has not publihsed types for this yet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // https://github.com/josemarluedke/glimmer-apollo/blob/main/packages/glimmer-apollo/src/-private/use-resource.ts
  function useUnproxiedResource(context, klass, thunk) {
    let resource;
    return {
      get value() {
        if (!resource) {
          resource = (0, _helper.invokeHelper)(context, klass, () => {
            return (0, _utils.normalizeThunk)(thunk);
          });
        }

        return (0, _cache.getValue)(resource); // eslint-disable-line
      }

    };
  }
  /**
   * For use in the body of a class.
   * Constructs a cached LifecycleResource that will reactively respond to tracked data changes
   *
   */


  function useResource(context, klass, thunk) {
    (false && !(isLifecycleResource(klass) || isSimpleResource(klass)) && (0, _debug.assert)("Expected second argument, klass, to be a Resource. " + "This is different from the v1 series where useResource could be used for both functions and class-based Resources. " + "If you intended to pass a function, you'll now (since v2) want to use useFunction instead", isLifecycleResource(klass) || isSimpleResource(klass)));
    let target = useUnproxiedResource(context, klass, thunk || _utils.DEFAULT_THUNK);
    return (0, _utils.proxyClass)(target);
  }

  function isLifecycleResource(classOrFn) {
    return classOrFn.prototype instanceof _lifecycle.LifecycleResource;
  }

  function isSimpleResource(classOrFn) {
    return classOrFn.prototype instanceof _simple.Resource;
  }
});